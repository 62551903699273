import React, { Component } from "react";
import {
  Card,
  Row,
  Col,
  Container,
  FormCheckbox,
  CardBody,
  Button
} from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Language, API_URL ,  HEADER_ORIGIN,
  HEADER_ORIGIN_KEY,} from "../../config";
import { strings } from "../../localization";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";

export default class ManageUserPermissions extends Component {
  state = {
    permissions: {},
    updatedPermissions: {},
    allPermissions: {
      display_bill: false,
      display_invoice: false,
      display_creditnote: false,
      display_deliverynote: false,
      display_debitnote: false,
      display_purchase_document: false,
      add_invoice: false,
      update_invoice: false,
      get_invoice: false,
      delete_invoice: false,
      add_debitnote: false,
      update_debitnote: false,
      get_debitnote: false,
      delete_debitnote: false,
      get_user: false,
      add_user: false,
      delete_user: false,
      update_user: false,
      add_permission: false,
      get_permission: false,
      update_permission: false,
      delete_permission: false,
      add_organisation: false,
      get_organisation: false,
      update_organisation: false,
      delete_organisation: false,
      add_bill: false,
      update_bill: false,
      get_bill: false,
      delete_bill: false,
      add_creditnote: false,
      update_creditnote: false,
      get_creditnote: false,
      delete_creditnote: false,
      add_deliverynote: false,
      update_deliverynote: false,
      get_deliverynote: false,
      delete_deliverynote: false,
      add_inquiry: false,
      update_inquiry: false,
      get_inquiry: false,
      delete_inquiry: false,
      add_item: false,
      update_item: false,
      get_item: false,
      delete_item: false,
      add_language: false,
      update_language: false,
      get_language: false,
      delete_language: false,
      add_sii: false,
      update_sii: false,
      get_sii: false,
      delete_sii: false,
      add_notification: false,
      update_notification: false,
      get_notification: false,
      delete_notification: false,
      add_notification_template: false,
      update_notification_template: false,
      get_notification_template: false,
      delete_notification_template: false,
      add_template: false,
      get_template: false,
      update_template: false,
      delete_template: false,
      // add_transactionxml: false,
      // update_transactionxml: false,
      get_transactionxml: false,
      // delete_transactionxml: false,
      all_users: false,
      all_folios: false,
      all_transactionxml: false,
      all_charts: false,
      enable_propina: false,
      add_dispatchnote: false,
      display_dispatchnote: false,
      get_dispatchnote: false,
      display_quote: false
    },
    visible: true,
    method: "post",
    id: "",
    checkAll: false
  };
  constructor(props) {
    super(props);
    strings.setLanguage(Language);
  }

  onChangeSwitch(key, value, k) {
    // alert(key);
    // alert(value);
    // let obj = { [key]: !value };
    // Object.assign(obj);

    let obj = this.state.updatedPermissions;

    obj[key] = !value;

    // console.log(obj);

    this.setState({
      permissions: {
        ...this.state.permissions,
        [key]: !value
      },
      updatedPermissions: obj
    });

    // console.log(this.state.updatedPermissions);

    // this.setState({
    //   data: oldArray
    // });
  }

  componentDidMount() {
    let id = this.props.match.params.id;

    if (id) {
      fetch(`${API_URL}permissions?userid=${id}`, {
        method: "GET",
        credentials: "include"
      })
        .then(res => res.json())
        .then(data => {
          let permissions = data.data.permissions;
          let id = data._id;
          // alert(id);
          if (!permissions.hasOwnProperty("add_debitnote")) {
            permissions.add_debitnote = false;
          }
          if (!permissions.hasOwnProperty("update_debitnote")) {
            permissions.update_debitnote = false;
          }
          if (!permissions.hasOwnProperty("get_debitnote")) {
            permissions.get_debitnote = false;
          }
          if (!permissions.hasOwnProperty("delete_debitnote")) {
            permissions.delete_debitnote = false;
          }
          if (!permissions.hasOwnProperty("all_users")) {
            permissions.all_users = false;
          }
          if (!permissions.hasOwnProperty("all_folios")) {
            permissions.all_folios = false;
          }
          if (!permissions.hasOwnProperty("all_transactionxml")) {
            permissions.all_transactionxml = false;
          }
          if (!permissions.hasOwnProperty("get_transactionxml")) {
            permissions.get_transactionxml = false;
          }
          if (!permissions.hasOwnProperty("all_charts")) {
            permissions.all_charts = false;
          }
          if (!permissions.hasOwnProperty("display_purchase_document")) {
            permissions.display_purchase_document = false;
          }
          if (!permissions.hasOwnProperty("enable_propina")) {
            permissions.enable_propina = false;
          }
          if (!permissions.hasOwnProperty("add_dispatchnote")) {
            permissions.add_dispatchnote = false;
          }
          if (!permissions.hasOwnProperty("display_dispatchnote")) {
            permissions.display_dispatchnote = false;
          }
          if (!permissions.hasOwnProperty("get_dispatchnote")) {
            permissions.get_dispatchnote = false;
          }
          if (!permissions.hasOwnProperty("display_quote")) {
            permissions.display_quote = false;
          }
          // }

          // console.log(permissions);
          if (permissions) {
            this.setState({
              permissions,
              visible: false,
              method: "put",
              id: id
            });
          } else {
            // alert("in");
            // this.setState({ method: "put" });
          }
        })
        .catch(e => {
          // alert("catch");
          this.setState({
            visible: false,
            permissions: this.state.allPermissions
          });
        });
    }

    // Object.fromEntries(
    //   Object.entries(this.state.permissions).map(([key, value]) => {
    //     console.log("Key : " + key);
    //     console.log("Value : " + value);
    //   })
    // );

    // this.mapObject(, );
  }

  checkAll() {
    //console.log(e.target);
    // console.log(e.checked);

    let array = this.state.allPermissions;

    // alert("on");
    let value = !this.state.checkAll;

    Object.keys(array).map(function (key, index) {
      array[key] = value;
    });

    this.setState({
      updatedPermissions: array,
      permissions: array,
      checkAll: value
    });
  }

  // mapObject(obj, fn) {
  //   return Object.fromEntries(Object.entries(obj).map(fn));
  // }

  renderItems() {
    let data = this.state.permissions;
    let JSX = [];
    for (var key in data) {
      let key_ = key;
      //this.onChangeSwitch(key, data[key]);
      // console.log(data[key] + " : " + key);
      JSX.push(
        <Col lg="3" md="4" sm="6" key={key}>
          <hr />
          <FormCheckbox
            onChange={() => this.onChangeSwitch(key_, data[key_])}
            checked={data[key] && true}
            toggle
          >
            <span style={{ textTransform: "capitalize" }}>
              {key.toString().replace(/_/g, " ")}
            </span>
          </FormCheckbox>
        </Col>
      );
    }
    return JSX;
  }

  handleClick() {
    let userInfo = localStorage.getItem("user_info");
    if (userInfo) {
      userInfo = JSON.parse(userInfo);
      // console.log(userInfo._id);
      this.setState({ button: true, loader: "fa fa-spinner fa-spin" });
      let currentUserID = this.props.match.params.id;
      // alert(this.state.method);
      let data = this.state.method === "put" ? "/" + this.state.id : "";
      let body = JSON.stringify({
        userID: currentUserID,
        permissions:
          this.state.method === "put"
            ? this.state.updatedPermissions
            : this.state.permissions,
        "p-source": "cp"
      });
      let responseStatus = "";
      // data[HEADER_ORIGIN_KEY] = HEADER_ORIGIN;
      fetch(`${API_URL}permissions${data}`, {
        method: this.state.method,
        headers: {
          "Content-Type": "Application/json",
          // "p-source":"cp"
        },
        credentials: "include",
        body: body
      })
        .then(res => {
          responseStatus = res.status;
          return res.json();
        })
        .then(data => {
          // console.log(data);
          if (responseStatus === 400) {
            toast.error(data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
          } else if (responseStatus === 200) {
            toast.success("Record saved successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
            // this.setState({ button: false, loader: "" });
          } else {
            toast.error(data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
          }
          this.setState({ loader: "", button: false });
        });
    }
  }

  render() {
    return (
      <div>
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <Col>
              <PageTitle
                title="Permissions"
                subtitle="Overview"
                md="12"
                className="ml-sm-auto mr-sm-auto"
              />
            </Col>

            <Col style={{ display: "flex", justifyContent: "flex-end" }}>
              <Link to={"/customers"} className="float-left">
                <Button
                  size="sm"
                  id="go_back"
                  theme="light"
                  className="mb-2 mr-1"
                >
                  {strings.go_back}
                </Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12" sm="12">
              <Card small className="mb-4">
                <CardBody className="p-3 pb-3">
                  {this.state.visible ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center"
                      }}
                    >
                      <Loader
                        type="Puff"
                        color="#00BFFF"
                        height={100}
                        width={100}
                        visible={this.state.visible}
                      />
                    </div>
                  ) : (
                    <>
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginRight: "10px"
                        }}
                      >
                        <FormCheckbox
                          toggle
                          onChange={() => this.checkAll()}
                          checked={this.state.checkAll && true}
                        >
                          Check All
                        </FormCheckbox>
                      </Row>
                      <Row>{this.renderItems()}</Row>
                      <ToastContainer />
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          theme="accent"
                          onClick={() => this.handleClick()}
                          disabled={this.state.button}
                        >
                          <i
                            className={this.state.loader}
                            style={{ fontSize: "15px" }}
                          ></i>{" "}
                          Save
                        </Button>
                      </div>
                    </>
                  )}
                  {/* {this.state.data.length > 0
                      ? this.state.data.map((value, k) => {
                          return (
                            <Col lg="3" md="4" sm="6">
                              <FormCheckbox
                                onChange={() =>
                                  this.onChangeSwitch(
                                    Object.keys(value),
                                    Object.values(value)[0],
                                    k
                                  )
                                }
                                toggle
                                checked={Object.values(value)[0] && true}
                              >
                                <span style={{ textTransform: "capitalize" }}>
                                  {Object.keys(value)
                                    .toString()
                                    .replace(/_/g, " ")}
                                </span>
                              </FormCheckbox>
                              <hr />
                            </Col>
                          );
                        })
                      : null} */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
